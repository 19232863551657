/*
 * @Descripttion: 服务支持路由
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-01-19 10:43:05
 */
import allUrls from '@/utils/config'
import { TRACK_ENTRY_MAP } from '@/utils/const/apply'
import { QueryServicecoverageClick } from '@/reporter/track'
// 服务支持---清关服务---分类
export const customsClearanceList = [
  // {
  //   name: 'AllCustomsClearanceServices',
  //   cname: '全部清关服务',
  //   path: '/support/customsClearance/allCustomsClearanceServices',
  //   il8nName: '全部清关服务',
  // },
  {
    name: 'TaxQuery',
    cname: '税金查询',
    path: '/support/customsClearance/taxQuery',
    il8nName: 'navs.taxQuery',
  },
  {
    name: 'UploadPhotos',
    cname: '上传清关图片',
    path: '/support/customsClearance/uploadPhotos',
    il8nName: 'navs.uploadPhotos',
  },
  {
    name: 'Infromaton',
    cname: '清关申报信息收集',
    path: '/support/customsClearance/infromaton',
    il8nName: 'navs.infromaton',
  },
  // {
  //   name: 'Declaration',
  //   cname: '低值通关申报',
  //   isMoblieOnly: true,
  //   path: allUrls.iorderIFCUrl,
  //   il8nName: 'navs.lowDeclaration',
  // },
  {
    name: 'DeclarationQuery',
    cname: '报关单查询',
    path: '/support/customsClearance/certificateQuery',
    il8nName: 'navs.declarationQuery',
  },
  {
    name: 'CustomsTaxFee',
    cname: '清关税费',
    path: '/support/customsClearance/customsTaxFee',
    il8nName: 'navs.customsTaxFee',
  },
  // {
  //   name: 'CertificateQuery',
  //   cname: '通关证明文件查询',
  //   hidden: { mb: false }, // 移动端隐藏
  //   path: '/support/customsClearance/certificateQuery',
  //   il8nName: 'navs.certificateQuery',
  // },
  {
    name: 'CustomsRequirements',
    cname: '进口清关指南',
    path: '/support/customsClearance/customsRequirements',
    il8nName: 'navs.customsRequirements',
  },
  {
    name: 'OnlineCustomsDeclarationPlatform',
    cname: '在线清关平台',
    path: '/support/customsClearance/apply',
    params: {
      query: { scene: TRACK_ENTRY_MAP.mainMenu },
    }, // 默认为顶部菜单
    hidden: { mb: false }, // 移动端隐藏,hiddenLangList表示隐藏的语言，此时表示英文下不展示
    // hidden: { mb: false, hiddenLangList: ['en'] }, // 移动端隐藏,hiddenLangList表示隐藏的语言，此时表示英文下不展示
    il8nName: 'serviceSupport.clearanceService.apply.title',
  },
]
// 服务支持---查询支持---分类
export const QuerySupportList = [
  {
    name: 'waybill',
    cname: '查运单',
    path: '/support/querySupport/waybill',
    il8nName: 'serviceSupport.querySupport.waybill',
  },
  {
    id: 'scope',
    name: 'service_scope',
    cname: '查收送范围',
    path: allUrls.sfExpressRangeUrl,
    trackFn: QueryServicecoverageClick,
    il8nName: 'serviceSupport.querySupport.service_scope',
  },
  {
    name: 'freight_aging',
    cname: '查运费时效',
    path: '/support/querySupport/freight_aging',
    il8nName: 'serviceSupport.querySupport.freight_aging',
  },
  {
    name: 'dept',
    cname: '查服务网点',
    path: '/support/querySupport/dept',
    il8nName: 'serviceSupport.querySupport.service_dept',
  },
  {
    name: 'posting_std',
    cname: '查收寄标准',
    path: '/support/querySupport/receiving_standard',
    il8nName: 'navs.posting_std',
  },
  {
    name: 'exce_rate',
    cname: '查汇率',
    path: '/support/querySupport/exce_rate',
    il8nName: 'serviceSupport.querySupport.exce_rate',
  },
  {
    name: 'holiday',
    cname: '查节假日服务计划',
    path: '/support/querySupport/holiday',
    il8nName: 'serviceSupport.querySupport.holidays.navTitle',
  },
  {
    name: 'fee_rate',
    cname: '查价格费率',
    path: '/support/querySupport/fee_rate',
    il8nName: 'serviceSupport.querySupport.fee_rate',
  },
  {
    name: 'download_file',
    cname: '文件自助下载',
    path: '/support/querySupport/download_file',
    il8nName: 'navs.downloadFile',
  },
]

export default {
  path: '',
  cname: '服务支持',
  name: 'support',
  il8nName: 'navs.serviceSupport',
  children: [
    {
      path: '',
      cname: '查询支持',
      name: 'querySupport',
      il8nName: 'navs.querySupport',
      children: QuerySupportList,
    },
    {
      path: '',
      cname: '清关服务',
      name: 'customsClearance',
      il8nName: 'navs.clearanceService',
      children: customsClearanceList,
    },
    {
      path: '/support/termsOfUse',
      cname: '使用条款',
      name: 'navs.termsOfUse',
      il8nName: 'navs.termsOfUse',
    },
    {
      path: '/support/privacyPolicy',
      cname: '隐私政策',
      name: 'serviceSupport.privacyPolicy',
      il8nName: 'navs.privacyPolicy',
    },
    {
      path: '/support/faq',
      cname: '常见问题解答',
      name: 'commst_problem',
      il8nName: 'navs.FAQ',
    },
    {
      path: '/support/suggestion',
      cname: '功能反馈',
      name: 'suggestion',
      il8nName: 'navs.funcSuggestion',
    },
    // {
    //   path: '',
    //   cname: '下载中心',
    //   name: 'download_center',
    //   il8nName: '',
    // },
  ],
}
