/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-12-19 10:30:00
 * @FilePath: \ibu-owt-core-frontend\services\apply.js
 * @Description: 在线申报接口
 */

import { coreUrl } from './api'
import { formatAsUrlParamString } from '@/utils/utils'
export default ($axios, store) => {
  return {
    // 在线清关平台-报关方式
    async queryCustomsTypesApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryCustomsTypes`,
        { params, ...config }
      )
      return res?.result
    },
    // 在线清关平台-列表数据
    async queryApplyOrderList(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryOrderManagementPage`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res
    },
    async getSwitchConfig(params = {}, config = {}) {
      const res = await $axios(`${coreUrl}/customsDeclaration/getSwitchConfig`, {
        params,
        backAll: true,
        ...config,
      })
      return res
    },
    // 在线清关平台-获取订单信息
    async getOrderInfo(params = {}, config = {}) {
      const res = await $axios(`${coreUrl}/customsDeclaration/getOrderInfo`, {
        params,
        backAll: true,
        ...config,
      })
      return res
    },
    // 在线清关平台-获取清关上传单证列表和申报模板（暂时不用）
    async getCustomsTemplate(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/getCustomsUploadListAndDeclareTemplate`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-获取清关上传单证列表和申报模板
    async getCustomsUploadAndDeclareTemplateApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/getCustomsUploadAndDeclareTemplate`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-申报字段模板下拉数据
    async getCustomsCache(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryDeclareTemplateCache`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-申报数据查询
    async queryCustomsData(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryDeclareData`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-获取清关申报日志
    async getCustomsDeclarationMethodApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsUpload/clearanceInformation/material/getCustomsDeclarationMethod`,
        {
          params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-解析压缩清关文件
    async uploadZipFileApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsUpload/clearanceInformation/material/decompression`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-申报数据保存
    async saveCustomsData(urlParams = {}, params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/saveDeclareData${formatAsUrlParamString(
          urlParams
        )}`,
        {
          method: 'POST',
          data: params,
          backAll: true,
          ...config,
        }
      )
      return res
    },
    // 在线清关平台-获取清关上传单证列表（详情页）
    async getCustomsUploadListApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/getCustomsUploadList`,
        {
          params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-证件上传-身份证OCR识别
    async getOcrIdentifyApi(params = {}, type, config = {}) {
      // 1 正面 2 国徽面
      let url =
        coreUrl +
        `/customsUpload/clearanceInformation/idCard/ocr/identify?type=${type}`
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '&respTranslate=tc'
      }
      const res = await $axios(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...config.headers,
        },
        data: params,
      })
      return res?.result
    },
    // 在线清关平台-证照单证上传
    getCertificateUploadApi(params = {}, config = {}) {
      let url =
        coreUrl + '/customsUpload/clearanceInformation/certificate/upload'
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '?respTranslate=tc'
      }
      return $axios(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...config.headers,
        },
        data: params,
        backAll: true,
      })
    },
    // 在线清关平台-清关单证上传
    getMaterialUploadApi(params = {}, config = {}) {
      let url = coreUrl + '/customsUpload/clearanceInformation/material/upload'
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '?respTranslate=tc'
      }
      return $axios(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...config.headers,
        },
        data: params,
        backAll: true,
      })
    },
    // 在线清关平台-获取订单详情信息
    async getOrderDetailInfoApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/getOrderDetailInfo`,
        {
          params,
          backAll: true,
          ...config,
        }
      )
      return res
    },
    // 在线清关平台-商品推荐
    async queryGoodsApi(params = {}, config = {}) {
      const res = await $axios(`${coreUrl}/customsDeclaration/queryGoods`, {
        params,
        ...config,
      })
      return res?.result
    },
    // 在线清关平台-品名校验+品名要素推荐
    async queryAcceptCriteriaApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/acceptCriteria/checkToken/overseas/list`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 在线清关平台-申报订单管理查询-手机验证码
    async queryOrderManagementPageFromTelApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryOrderManagementPageFromTel`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res
    },
    // 在线清关平台-申报订单管理查询-运单号逐票
    async queryOrderManagementPageFromMailnoApi(
      urlParams = {},
      params = {},
      config = {}
    ) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryOrderManagementPageFromMailno`,
        {
          method: 'POST',
          params: urlParams,
          data: params,
          ...config,
        }
      )
      return res
    },
    // 在线清关平台-获取订单详情信息
    async getCanEditfieldsApi(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryCanEditfieldsByBno`,
        {
          method: 'POST',
          data: params,
          backAll: true,
          ...config,
        }
      )
      return res
    },
    // 提交历史
    async querySubmitLogCondition(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/querySubmitLogCondition`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
    // 列表查看数据 接口
    async getFileContentSafe(params = {}, config = {}) {
      const res = await $axios(
        // `${coreUrl}/customsDeclaration/getFileByOssName`,
        `${coreUrl}/customsDeclaration/getFileContentSafe`,
        {
          params,
          ...config,
        }
      )
      return res?.result
    },
    // 查询提交日志文件列表
    async querySubmitLogFileList(params = {}, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/querySubmitLogFileList`,
        {
          params,
          ...config,
        }
      )
      return res?.result
    },
  }
}
