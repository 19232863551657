
import '@/utils/imgLazyload'
import { requireCdnUrl, isWebP, isLocalImgResource } from '@/utils/utils'
export default {
  name: 'Imglazy',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: 'img',
    },
    isLazy: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    // 是否检测webp图片是否加载成功，默认不检测，检测的话，如果webp图片加载失败，则使用普通图片
    isCheckWebp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    // 非懒加载 进行替换处理
    normalWebpUrl() {
      if (this.src) {
        const { isError, url } = requireCdnUrl(this.src, true)
        // 只有加载本地图片时，才会存在webp报错, 重新获取新的本地原图资源
        return isError ? requireCdnUrl(this.src).url : url
      }
      return ''
    },
    // 非懒加载 进行替换处理
    normalUrl() {
      if (this.src) {
        const { url } = requireCdnUrl(this.src)
        return url
      }
      return ''
    },
  },
  watch: {
    normalWebpUrl: {
      immediate: true,
      handler(newValue) {
        this.checkImage(newValue)
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    checkImage(src) {
      // 非懒加载 且 需要检测webp图片是否加载成功，则进行检测处理
      if (process.client && !this.isLazy && this.isCheckWebp) {
        const _this = this
        // 暂只对webp图片做检测处理，其他图片不做处理
        if (isWebP(src)) {
          const webpImage = new Image()
          webpImage.src = src
          webpImage.onerror = function () {
            const { isLocalImg } = isLocalImgResource(_this.src)
            if (isLocalImg) {
              // 本地图片加载失败，则使用降级图片加载
              _this.$refs.webpRef.srcset = _this.normalUrl
            } else {
              // 远程图片加载失败，则使用普通图片加载
              _this.$refs.webpRef.srcset = _this.src
            }
          }
        }
      }
    },
    // 图片加载失败的处理
    imgError(e) {
      const { url } = requireCdnUrl(this.src, false)
      // 有效的图片资源，则重新加载图片资源
      this.$refs.imgLazyRef.src = url
    },
  },
}
