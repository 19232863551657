import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import { requireCdnUrl } from '@/utils/utils'
Vue.use(VueLazyload, {
  lazyComponent: true,
  silent: true,
  dispatchEvent: true,
  attempt: 1,
  filter: {
    // 图片内置到域名下
    webp(listener, options) {
      // 对浏览器端-懒加载图片进行处理
      const src = listener.src
      const { isError, url } = requireCdnUrl(src, options.supportWebp)
      // 正常流程
      if (!isError) {
        listener.src = url
      } else {
        const { url: normalImg } = requireCdnUrl(src, false)
        // 图片不支持webp-则使用原图进行兜底
        listener.src = normalImg
      }
    },
  },
})
