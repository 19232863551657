/*
 * @Author: liyang
 * @Date: 2021-11-03 19:13:14
 * @FilePath: \ibu-owt-core-frontend\services\api.js
 * @Description: 请求接口-自动注入AXIOS
 */
import qs from 'qs'
import applyApis from './apply'
export const coreUrl = '/site/core-service/web'
// const cmsUrl = '/site/cms-service/web'
const icrmUrl = '/site/icrm'
const signIcrmUrl = '/sign/site/icrm'
const signCmsUrl = '/sign/site/cms-service/web'

export default ($axios, store) => ({
  // 首页模块接口
  home: {
    // 获取首页信息-get
    async getHomePage(params) {
      const res = await $axios(signCmsUrl + '/getHomePage', {
        // 方法 默认 GET 非必须
        // method: 'GET',
        // 参数
        params,
        // 是否返回 完整参数 结果 默认false 非必须
        // backAll: false,
      })
      return res?.result
    },
  },
  shipping: {
    // 寄国际件指南
    async getInternationalPartsGuide(params = {}) {
      const res = await $axios(
        `${signCmsUrl}/shippingCenter/getInternationalPartsGuide`,
        {
          params,
        }
      )
      return res?.result
    },
  },
  // 物流产品模块接口
  product: {
    getLogisticsService(params) {
      return $axios(signCmsUrl + '/getLogisticsService', {
        // 参数
        params,
      })
    },
    // 获取物流服务下所有菜单
    getLogisticsServiceMenuApi(params) {
      return $axios(signCmsUrl + '/getLogisticsServiceMenu', {
        // 参数
        params,
      })
    },
    // 物流服务主页
    getLogisticsServiceMain(params) {
      return $axios(signCmsUrl + '/getLogisticsServiceMain', {
        method: 'POST',
        data: params,
      })
    },
  },
  // 服务支持模块接口
  support: {
    // 查运单-获取站点时区
    async getTimeZoneApi(params) {
      const res = await $axios(coreUrl + '/bills/getTimeZone', {
        // 方法 默认 GET 非必须
        // method: 'GET',
        // 参数
        params,
      })
      return res?.result || ''
    },
    // 运单号查询路由信息--反脱敏数据
    async billRouteApi(params, cap) {
      const res = await $axios(
        `${coreUrl}/bills/id/routes?${qs.stringify(cap)}`,
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result || ''
    },
    // 批量运单查询-三星件-取消预约
    async cancelDirectiveApi(params = {}) {
      const res = await $axios(`${coreUrl}/bills/cancelDirective`, {
        params,
      })
      return res?.result
    },
    // 通关运单号 查询数据
    async queryReceiverInfo(params = {}) {
      const res = await $axios(`${coreUrl}/bills/queryReceiverInfo`, {
        params,
      })
      return res?.result
    },
    // 更改数据后 提交
    async submitDirective(params) {
      const res = await $axios(
        `${coreUrl}/bills/modifyReceiver/submitDirective`,
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result || ''
    },
    // 批量运单查询-三星件-验证页提交
    async recordsDirectiveApi(params = {}) {
      const res = await $axios(`${coreUrl}/bills/directive`, {
        params,
      })
      return res?.result
    },
    // 批量运单查询-三星件-预约派送/自取 提交
    async submitDirectiveApi(params = {}) {
      const res = await $axios(`${coreUrl}/bills/submitDirective`, {
        params,
      })
      return res?.result
    },
    // 批量运单查询-三星件-修改收件人手机号验证页面提交
    async modifyReceiverDirectiveApi(params = {}) {
      const res = await $axios(`${coreUrl}/bills/modifyReceiver/directive`, {
        params,
      })
      return res?.result
    },
    // 批量运单查询-三星件-修改收件人手机号验证页面提交
    async generateSignatureApi(params = {}) {
      const res = await $axios(`${coreUrl}/bills/iccsp/generateSignature`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 获取目标汇率
    async getExrateDestApi(params) {
      const res = await $axios(coreUrl + '/exrate/query', {
        // 方法 默认 GET 非必须
        // method: 'GET',
        // 参数
        params,
      })
      return res?.result
    },
    // 查询服务网点
    async getQueryServiceApi(params) {
      const res = await $axios(coreUrl + '/bound/queryService', {
        params,
      })
      return res?.result
    },
    // 模糊邮编查询
    async getPostBound(params) {
      const res = await $axios(coreUrl + '/newRate/queryBoundLivePostCode', {
        params,
      })
      return res?.result
    },
    // 查询运费时效
    async getFreightPriceApi(params = {}) {
      const res = await $axios(coreUrl + '/newRate/freightPrice/query', {
        params,
      })
      return res?.result
    },
    // 查节假日服务计划---查询国家名称信息
    async getQueryCountryNamesApi(params = {}) {
      const res = await $axios(coreUrl + '/hoildays/queryCountryNames', {
        params,
      })
      return res?.result
    },
    // 查节假日服务计划---根据国家名称查询节假日
    async getQueryHolidayApi(params = {}) {
      const res = await $axios(coreUrl + '/hoildays/queryHoliday', {
        params,
      })
      return res?.result
    },
    // 文件自助下载---获取文件类型
    async getFileDownloadTypeApi(params = {}) {
      const res = await $axios(
        signCmsUrl + '/shippingCenter/getFileSelfDownloadType',
        params
      )
      return res?.result
    },
    // 文件自助下载---获取文件列表
    async getFileDownloadListApi(params = {}) {
      const res = await $axios(
        signCmsUrl + '/shippingCenter/getFileSelfDownload',
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result
    },
    // 文件下载---kyc文件下载
    async getKycTemplate(params = {}) {
      const res = await $axios(signCmsUrl + '/shippingCenter/getKycTemplate', {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 文件下载---印度发票文件下载
    async getInvoiceTemplate(params = {}) {
      const res = await $axios(
        signCmsUrl + '/shippingCenter/getInvoiceTemplate',
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result
    },
    // 文件下载---帮助文档文件下载
    async getHelpGuideTemplate(params = {}) {
      const res = await $axios(
        signCmsUrl + '/shippingCenter/getHelpDocumentTemplate',
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result
    },
    // 申报工具-跳转到页面获取订单详情信息
    async getDetailInfoFromLink(params = {}, cap) {
      const res = await $axios(
        coreUrl +
          `/customsDeclaration/getDetailInfoFromLink?${qs.stringify(cap)}`,
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result
    },
    // 税金查询 查询按钮
    async getTaxFusionResult(params = {}) {
      const res = await $axios(coreUrl + '/customsTax/getTaxFusionResult', {
        params,
      })
      return res?.result
    },
    //  清关税金查询
    async getTaxManagementDataApi(params = {}) {
      const res = await $axios(coreUrl + '/customsTax/getTaxManagementData', {
        params,
      })
      return res?.result
    },
    // 清关税金凭证 || 税单
    async getinvoiceApi(imageType, params = {}) {
      const res = await $axios(coreUrl + `/customsTax/${imageType}/invoice`, {
        params,
      })
      return res?.result
    },
    // 清关税金凭证----税金支付
    async getTaxPayData(params = {}) {
      const res = await $axios(coreUrl + '/customsTax/getTaxPayData', {
        params,
      })
      return res?.result
    },
    // 清关税金凭证----详情中图片查询
    async queryTaxImageApi(params = {}) {
      const res = await $axios(coreUrl + '/customsTax/queryImage', {
        params,
      })
      return res?.result
    },
    // 清关税金凭证----获取税金pdf
    async getTaxPdfApi(waybillNo = {}, params) {
      const res = await $axios(coreUrl + `/customsTax/${waybillNo}/getPdf`, {
        params,
      })
      return res?.result
    },
    // 中国进口税金查询
    async getImportedChinaTaxManagementDataApi(params = {}) {
      let url = coreUrl + `/customsTax/getImportedChinaTaxManagementData`
      const langMap = {
        'zh-HK': '?respTranslate=hk',
        'zh-TW': '?respTranslate=tc',
        'en-TW': '?respTranslate=TW-EN',
      }
      if (['zh-HK', 'zh-TW', 'en-TW'].includes(store.state.locale)) {
        url += langMap[store.state.locale]
      }
      const res = await $axios(url, {
        params,
      })
      return res?.result
    },
    // 中国进口税金凭证
    async getImportedChinaTaxVoucherDataApi(waybillNo) {
      const res = await $axios(
        coreUrl + `/customsTax/${waybillNo}/getImportedChinaTaxVoucherData`,
        {
          method: 'POST',
        }
      )
      return res?.result
    },
    // 中国进口税金--获取支付二维码
    async getPayQrCode(params = {}) {
      const res = await $axios(coreUrl + `/customsTax/getPayQrCode`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 中国进口税金--刷新支付二维码
    async refreshPayQrCode(params = {}) {
      const res = await $axios(coreUrl + `/customsTax/refreshPayQrCode`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 中国进口税金--获取支付状态
    async getPayStatus(reqId) {
      const res = await $axios(coreUrl + `/customsTax/${reqId}/payStatus`, {
        method: 'POST',
      })
      return res?.result
    },

    // 常见问题解答
    async getFrequentlyAskedQuestions(params = {}) {
      const res = await $axios(
        signCmsUrl + '/shippingCenter/getFrequentlyAskedQuestions',
        {
          method: 'GET',
          params,
        }
      )
      return res?.result
    },
    // 获取常见问题解答tab页
    async getFrequentlyAskedQuestionsTab(params = {}) {
      const res = await $axios(signCmsUrl + '/getAskQuestionTab', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 清关服务---证件上传-身份证OCR识别
    async getOcrIdentify(params = {}, type) {
      // 1 正面 2 国徽面
      let url = coreUrl + `/customsUpload/idCard/ocr/identify?type=${type}`
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '&respTranslate=tc'
      }
      const res = await $axios(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params,
      })
      return res?.result
    },
    // 清关服务---证照单证上传
    getCertificateUpload(params = {}) {
      let url = coreUrl + '/customsUpload/certificate/upload'
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '?respTranslate=tc'
      }
      return $axios(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params,
        backAll: true,
      })
    },
    // 清关服务---清关单证上传
    getMaterialUpload(params = {}) {
      let url = coreUrl + '/customsUpload/material/upload'
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '?respTranslate=tc'
      }
      return $axios(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params,
        backAll: true,
      })
    },
    // 清关服务--通关许可书--下拉手机国家区划列表
    async getCustomsDeclareCountryListApi(params = {}) {
      const res = await $axios(coreUrl + '/customsDeclare/source/country', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    async queryProvinceCityByPostCodeApi(params = {}) {
      const res = await $axios(
        coreUrl + '/newRate/queryProvinceCityByPostCode',
        {
          method: 'GET',
          params,
        }
      )
      return res?.result
    },
    // 清关服务--通关许可书--手机号码校验
    async checkPhoneApi(params = {}) {
      const res = await $axios(coreUrl + '/customsClearance/checkPhone', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 获取国家的接口
    async getCountries(params = {}) {
      const res = await $axios(coreUrl + '/newRate/countries', {
        method: 'GET',
        params,
      })
      return res?.result
    },

    // 清关服务--通关许可书--通关许可书（税单）查询
    async customsClearanceQueryApi(params = {}) {
      const res = await $axios(coreUrl + '/customsClearance/query', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 清关服务--通关许可书--发送邮箱
    async sendEmailApi(params = {}) {
      const res = await $axios(coreUrl + '/customsClearance/sendEmail', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 清关服务--通关许可书--发送短信
    async sendSmsApi(params = {}) {
      const res = await $axios(coreUrl + '/customsClearance/sendSms', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 收寄标准热门推荐
    async getPopularRecommandedList(params = {}) {
      const res = await $axios(coreUrl + '/acceptCriteria/popular/recommend', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 收寄标准品名推荐
    async getRecommandedCargoName(params = {}) {
      const res = await $axios(
        coreUrl + '/acceptCriteria/recommended/new/cargoName',
        {
          method: 'GET',
          params,
        }
      )
      return res?.result
    },
    // 查询托寄物海外收寄标准
    async getOverseasListApi(params = {}) {
      const res = await $axios(coreUrl + '/acceptCriteria/overseas/list', {
        method: 'POST',
        data: params,
      })
      return res
    },
    // 根据始发地 判断是否开通该地址
    async isNoOpenArea(params = {}) {
      const res = await $axios(
        coreUrl + '/acceptCriteria/getIpeReachableFlow',
        {
          method: 'GET',
          params,
        }
      )
      return res?.result
    },
    // 查询托寄物海外收寄标准
    async getOverseasListTcApi(params = {}) {
      const res = await $axios(
        coreUrl +
          '/acceptCriteria/overseas/list?reqTranslate=sc&respTranslate=tc',
        {
          method: 'POST',
          data: params,
        }
      )
      return res
    },
    // 获取使用条款和隐私协议
    async getTermsOfUse(params = {}) {
      const res = await $axios(signCmsUrl + '/shippingCenter/getTermsOfUse', {
        method: 'GET',
        params,
      })
      return res?.result
    },
    // 清关申报信息收集--查询运单信息--腾讯滑块
    async getCustomsInfoQueryApi(params) {
      const res = await $axios(`${coreUrl}/customsDeclare/waybillNo/query`, {
        params,
      })
      return res?.result
    },
    // // 清关申报信息收集--查询运单信息--顺丰滑块
    // async getCustomsInfoQuerySFApi(params, headers) {
    //   const res = await $axios(`${coreUrl}/customsDeclare/waybillNo/sf/query`, {
    //     params,
    //     headers,
    //   })
    //   return res?.result
    // },
    // 清关申报信息收集--获取原寄地国家
    async getCountry(params) {
      const res = await $axios(`${coreUrl}/customsDeclare/source/country`, {
        params,
      })
      return res?.result
    },
    // 清关申报信息收集--提交填写信息
    async getCustomsInfoSubmitApi(params = {}) {
      const res = await $axios(`${coreUrl}/customsDeclare/info/submit`, {
        method: 'POST',
        data: params,
        backAll: true,
      })
      return res
    },
    // 清关申报信息收集--寄方证件号码校验
    async checkConsignorCertificateNoApi(params = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclare/info/checkConsignorCertificateNo`,
        {
          method: 'POST',
          data: params,
          backAll: true,
        }
      )
      return res
    },
    // 清关申报信息收集--收方证件号码校验
    async checkReceiverCertificateNoApi(params = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclare/info/checkReceiverCertificateNo`,
        {
          method: 'POST',
          data: params,
          backAll: true,
        }
      )
      return res
    },
    // 清关申报信息收集--上传图片
    // async getCustomsInfoImgUploadApi(params = {}) {
    //   const res = await $axios(`${coreUrl}/customsDeclare/image/upload`, {
    //     method: 'POST',
    //     data: params,
    //   })
    //   return res?.result
    // },
    // 报关单查询--报关单查询
    async customsDeclarationQueryApi(params = {}) {
      const res = await $axios(`${coreUrl}/customsDeclaration/query`, {
        params,
      })
      return res?.result
    },
    // 税费收据查询
    async queryReceiptApi(params = {}) {
      const res = await $axios(
        `${coreUrl}/customsTax/queryReceipt`, 
        {
          params,
          // backAll: true,
        }
      )
      return res
    },
    // 税金批量查询
    async queryClearanceFileBatchApi(params = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryClearanceFileBatch`,
        {
          params,
        }
      )
      return res?.result
    },
    // 税金批量查询 批量下载的接口
    async compressionAndDownload(params) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/compressionAndDownload`,
        {
          method: 'POST',
          data: params,
          responseType: 'blob',
        }
      )
      return { data: res.data , name: res.headers['content-disposition'] }
    },
    // 税金批量查询  单个下载
    async getFileByOssName(params = {}) {
      const res = await $axios(
         `${coreUrl}/customsDeclaration/getFileByOssName`,
        {
          params,
          // ...config,
        }
      )
      return res?.result
    },
    // 报关单查询--报关单查询
    async getCustomsTaxFeeApi(params = {}, config) {
      const res = await $axios(
        signCmsUrl + `/shippingCenter/getCustomsTaxFee`,
        {
          params,
          ...config,
        }
      )
      return res?.result
    },
    // 目的国进口正式报关标准
    async getDestinationCountryCustomsClearanceApi(config) {
      const res = await $axios(
        `${signCmsUrl}/shippingCenter/getDestinationCountryCustomsClearance`,
        {
          params: {},
          ...config,
        }
      )
      return res?.result
    },
    // 发票申请--获取图片验证码
    async getInvoiceCaptcha(params) {
      const res = await $axios(`${coreUrl}/captcha/invoice`, {
        params,
      })
      return res?.result
    },
    // 发票申请--发票申请--滑块验证
    async getSlideInvoice(waybill, params, mobileNoLast4) {
      const res = await $axios(`${coreUrl}/invoice/${waybill}?mobileNoLast4=${mobileNoLast4}`, {
        params,
      })
      return res?.result
    },
    async getSlideInvoiceTwo(waybill, params) {
      const res = await $axios(`${coreUrl}/invoice/${waybill}`, {
        params,
      })
      return res?.result
    },
    // 发票申请--发票申请--马来站点--邮箱、手机号验证模式
    async getSlideMyInvoiceApi(waybill, params) {
      const res = await $axios(`${coreUrl}/invoice/my/${waybill}`, {
        params,
      })
      return res?.result
    },
    // 发票申请--越南站点--发票查询
    async getSlideVnInvoice(params, headers, cap) {
      const res = await $axios(
        `${coreUrl}/invoice/queryVnInvoice?${qs.stringify(cap)}`,
        {
          method: 'POST',
          data: params,
          headers,
          backAll: true,
        }
      )
      return res
    },
    // 发票申请--越南站--计算价格
    async queryVnInvoiceNoSlider(params, headers) {
      const res = await $axios(`${coreUrl}/invoice/queryVnInvoiceNoSlider`, {
        method: 'POST',
        data: params,
      })
      return res.result
    },
    // 发票申请--越南站点--发票申请
    async getVnInvoiceApply(params) {
      const res = await $axios(`${coreUrl}/invoice/makeVnInvoice`, {
        method: 'POST',
        data: params,
      })
      return res.result
    },

    // 服务支持内容---清关注意事项
    // 服务支持类型type 1-使用条款 2-隐私政策 3-运费时效 4-收寄标准 5-清关注意事项 6-服务网点 7-联系我们 8-授权合作伙伴服务点 9-人才招聘 10-国际业务介绍 11-寄件方式
    async getSupportContent(params = {}, config = {}) {
      const res = await $axios(
        `${signCmsUrl}/shippingCenter/getServiceSupportContent`,
        {
          params,
          ...config,
        }
      )
      return res?.result
    },
    // 查服务网点-icms
    async getGlobalServiceNet(params = {}) {
      const res = await $axios(`${coreUrl}/bound/queryGlobalService`, {
        params,
      })
      return res?.result
    },
    // 查服务网点-cms
    async getCmsServiceNet(params = {}) {
      const res = await $axios(
        `${signCmsUrl}/shippingCenter/getServiceSupportContent`,
        {
          params,
        }
      )
      return res?.result
    },
    // 查税种和清关服务费说明文案-cms
    async getTaxFeeNotice(params = {}) {
      let url = signCmsUrl + '/shippingCenter/getFormContent'
      if (['zh-HK', 'zh-TW'].includes(store.state.locale)) {
        url += '?respTranslate=tc'
      }
      const res = await $axios(url, {
        params,
      })
      return res?.result
    },
    // 获取价格费率
    async getQuote(params = {}) {
      const res = await $axios(`${signCmsUrl}/shippingCenter/getQuote`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 派送单验证码获取
    async getgetDeliveryCode(params = {}) {
      const res = await $axios(`${coreUrl}/captcha/receipt`, {
        params,
      })
      return res?.result
    },
    // 派送单查询 旧
    async getDeliveryCert(waybillNo, params = {}) {
      const res = await $axios(`${coreUrl}/bills/${waybillNo}/image`, {
        params,
      })
      return res?.result
    },
    // 马来站不是GE+展示签收证明 新
    async getDeliveryCertNew(params, cap) {
      const res = await $axios(
        `${coreUrl}/bills/notPodQuery?${qs.stringify(cap)}`,
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result
    },
    // POD 查询
    async queryPod(params, cap) {
      const res = await $axios(
        `${coreUrl}/bills/podQuery?${qs.stringify(cap)}`,
        {
          method: 'POST',
          data: params,
        }
      )
      return res?.result
    },
    // 获取是否需要滑块
    async getShowSlider(params = {}) {
      const res = await $axios(`${coreUrl}/bills/getShowSlider`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    ...applyApis($axios, store),
  },
  // 关于国际模块接口
  about: {
    // 关于顺丰国际查询接口
    async getCompanyInfoAndInformation(params = {}) {
      const res = await $axios(`${signCmsUrl}/getCompanyInfoAndInformation`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 详情
    async getOneInformationWithAround(params = {}) {
      const res = await $axios(`${signCmsUrl}/getOneInformationWithAround`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 全文搜索资讯中心
    async getMessage(params = {}) {
      const res = await $axios(`${signCmsUrl}/getMessage`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 根据id查询服务支持内容
    async getServiceContent(params = {}) {
      const res = await $axios(
        `${signCmsUrl}/shippingCenter/getServiceSupportContentById`,
        {
          params,
        }
      )
      return res?.result
    },
    async getInfoYearTab(params = {}) {
      const res = await $axios(`${signCmsUrl}/getYearTab`, {
        method: 'GET',
        params,
      })
      return res?.result
    },
  },
  // 落地页
  landing: {
    // 批量新增或者更新商机收集表信息
    saveBatchOpportunityCollectionApi(params) {
      return $axios(signCmsUrl + '/saveBatchOpportunityCollection', {
        method: 'POST',
        data: params,
      })
    },
  },
  // 公用接口
  common: {
    // 获取重要公告-get
    async getAnnouncementSidebarApi(params) {
      const res = await $axios(signCmsUrl + '/getAnnouncementSidebar', {
        // 方法 默认 GET 非必须
        // method: 'GET',
        // 参数
        params,
        // 是否返回 完整参数 结果 默认false 非必须
        // backAll: false,
      })
      return res?.result
    },
    // 获取cookie最新可用条款文件
    async getLatestTermRichTextApi(params = {}, lang) {
      const res = await $axios(
        `${signIcrmUrl}/term/richtext/getLatestTermRichText`,
        {
          params,
          headers: {
            lang,
          },
          backAll: true,
        }
      )
      return res
    },
    // 获取cookie最新可用条款文件id
    async getLatestTermRichTextOfId(params = {}) {
      const res = await $axios(`${icrmUrl}/term/richtext/termRichText/detail`, {
        params,
        backAll: true,
      })
      return res
    },

    // 原寄地模糊查询
    async getLiveOriginApi({ query, level, limit = 100 }) {
      const res = await $axios(`${coreUrl}/rate/live/origin`, {
        params: { query, level, limit },
      })
      return res?.result
    },
    // 目的地模糊查询
    async getLiveOriginDestsApi({ originCode, query, level, limit = 100 }) {
      const res = await $axios(
        `${coreUrl}/rate/live/origin/${originCode}/dests`,
        {
          params: { query, limit, level },
        }
      )
      return res?.result
    },
    // 逐级查询省市信息(中国内地|港澳台)
    async getAddrDistIdByNameApi(params = {}) {
      const res = await $axios(`${coreUrl}/rate/step/queryAddrDistIdByName`, {
        params,
      })
      return res?.result
    },
    // 逐级查询省市信息(国际)
    async getProvinceCityDistrictApi(params = {}) {
      const res = await $axios(
        `${coreUrl}/rate/step/queryProvinceCityDistrict`,
        {
          params,
        }
      )
      return res?.result
    },
    // 通过parentDistCode查询district列表 （选择中国内地省/直辖市）
    // parentDistCode 上一级地区代码
    // level 等级
    async getRateSubApi({ parentDistCode, level }) {
      const res = await $axios(
        `${coreUrl}/rate/${parentDistCode}/chineseMainland/sub`,
        {
          params: { level },
        }
      )
      return res?.result
    },
    // 原寄地选择港澳台
    async getRateHMTSubApi({ parentDistCode, level }) {
      const res = await $axios(`${coreUrl}/rate/${parentDistCode}/hmt/sub`, {
        params: { level },
      })
      return res?.result
    },
    // 逐级查询省市信息(国际)
    // parentDistCode 上一级地区代码
    // level 等级
    async getRateSubOriginsApi({ parentDistCode, level }) {
      const res = await $axios(
        `${coreUrl}/rate/${parentDistCode}/sub/origins`,
        {
          params: { level },
        }
      )
      return res?.result
    },
    // 目的地选择国际 获取国家
    async getRateSubDestsApi({ parentDistCode, level, originCode }) {
      const res = await $axios(`${coreUrl}/rate/${parentDistCode}/sub/dests`, {
        params: { level, originCode },
      })
      return res?.result
    },

    /** 改造新 */
    // 逐级查询省市信息
    async queryProvinceCityApi(params) {
      const res = await $axios(`${coreUrl}/newRate/step/queryProvinceCity`, {
        params,
      })
      return res?.result
    },
    /** 改造新 */
    // 模糊搜索
    async fuzzySearchApi(params) {
      const res = await $axios(`${coreUrl}/newRate/live/query`, {
        params: { limit: 100, ...params },
      })
      return res?.result || []
    },
    // 新的模糊搜索--支持国际二、三级区划
    async newFuzzySearchApi(params) {
      const res = await $axios(`${coreUrl}/newRate/live/queryIcms`, {
        params: { limit: 100, ...params },
      })
      return res?.result || []
    },
    // 中国内地省市信息
    async queryTotalProvinceCityApi() {
      const res = await $axios(`${coreUrl}/newRate/china/queryProvinceCity`, {
        params: { country: 'CN' },
      })
      return res?.result
    },
    // 查询热门城市（中国内地常用市）
    async getPopularCitiesApi(params = {}) {
      const res = await $axios(`${coreUrl}/newRate/popularCities`, {
        params,
      })
      return res?.result
    },
    // 原寄地 | 目的地 选择国家
    async getRateCountrysApi(params) {
      const res = await $axios(`${coreUrl}/newRate/countrys`, {
        params,
      })
      return res?.result
    },
    // 获取邮编信息(国际)
    getScenePostApi(params = {}, query) {
      let url = '/newRate/getPostCode'
      url = query ? url + query : url
      return $axios(`${coreUrl}${url}`, {
        method: 'POST',
        data: params,
      })
    },
    //
    queryPostCodeInfo(params = {}, query) {
      let url = '/newRate/queryPostCodeInfo'
      url = query ? url + query : url
      return $axios(`${coreUrl}${url}`, {
        method: 'POST',
        data: params,
      })
    },
    // 国家和邮编反查区划
    // async queryProvinceCityByPostCodeApi(params = {}) {
    //   const res = await $axios(
    //     `${coreUrl}/newRate/queryProvinceCityByPostCode`,
    //     {
    //       params,
    //     }
    //   )
    //   return res?.result
    // },
    // 国家和邮编模糊搜索
    async queryLivePostCodeApi(params = {}, config = {}) {
      const res = await $axios(`${coreUrl}/newRate/queryLivePostCode`, {
        params,
        noErrorMsg: config.noErrorMsg,
      })
      return res?.result
    },
    async queryBoundLivePostCode(params = {}, config = {}) {
      const res = await $axios(`${coreUrl}/newRate/queryBoundLivePostCode`, {
        params,
        noErrorMsg: config.noErrorMsg,
      })
      return res?.result
    },
    // 查询国家、省、市/区县完整信息
    async queryProvinceCityInfoApi(params = {}) {
      const res = await $axios(
        `${coreUrl}/newRate/step/queryProvinceCityInfo`,
        {
          params,
        }
      )
      return res?.result
    },

    // 公用验证码
    async getcaptchaApi(flag) {
      const res = await $axios(`${coreUrl}/captcha/${flag}`)
      return res?.result
    },
    // -------------滑块验证码功能-----------------
    // 生成顺丰验证码图片
    async getCaptchaImageApi(params) {
      const res = await $axios(`${coreUrl}/captcha/captchaImage`, {
        params,
      })
      return res?.result
    },
    // 生成顺丰验证码图片
    async checkCaptchaApi(params) {
      const res = await $axios(`${coreUrl}/captcha/checkCaptcha`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // // 批量运单查询路由信息-顺丰滑动验证码
    // async sfRoutesApi(params = {}) {
    //   const { ids, ...rest } = params
    //   const res = await $axios(`${coreUrl}/bills/${ids}/sf/routes`, {
    //     params: rest,
    //   })
    //   return res?.result
    // },
    // 批量运单查询路由信息-腾讯滑动验证码
    async tecentRoutesApi(params = {}) {
      const { ids, ...rest } = params
      const res = await $axios(`${coreUrl}/bills/${ids}/routes`, {
        params: rest,
        backAll: true,
      })
      return res
    },
    // -------------登录功能-----------------
    // 获取用户信息
    async getUserInfoApi(params = {}) {
      const res = await $axios(`${coreUrl}/newUser/getuserinfo`, {
        params,
      })
      return res?.result
    },
    // 退出
    async loginOutApi(params = {}) {
      const res = await $axios(`${coreUrl}/newUser/logout`, {
        params,
      })
      return res?.result
    },
    // oss图片token
    async getImgLinkToken(params = {}) {
      const res = await $axios(`${signCmsUrl}/getOssToken`, {
        params,
      })
      return res?.result
    },
    // 百度推广回传接口
    async passBackToBaidu(params) {
      const res = await $axios(`${coreUrl}/baidu/backhaulBaiduApi`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 去掩码
    async getDelMasksApi(params) {
      const res = await $axios(`${coreUrl}/mask/getDelMasks`, {
        method: 'POST',
        data: params,
      })
      return res?.result
    },
    // 解密
    async getDecryptData(params, config = {}) {
      const res = await $axios(`${coreUrl}/mask/getDecryptData`, {
        method: 'POST',
        data: params,
        ...config,
      })
      return res?.result
    },
    async queryExchangeRate(params, config = {}) {
      const res = await $axios(
        `${coreUrl}/customsDeclaration/queryExchangeRate`,
        {
          method: 'POST',
          data: params,
          ...config,
        }
      )
      return res?.result
    },
  },
})
